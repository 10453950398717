<template>
  <v-dialog
    ref="dialog"
    v-model="modal"
    persistent
    :width="config.width || '500px'"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-list-item
        v-if="!showButton"
        small
        dense
        depressed
        outlined
        style="min-width: 67px; max-width: 300px"
        v-bind="attrs"
        v-on="on"
        @click="modal = !modal"

      >
        <v-list-item-icon>
          <v-icon
            size="20"
            class="me-2"
          >
            {{ icons.mdiPencilOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ config.btnText }}
          </v-list-item-title>
        </v-list-item-content>

      </v-list-item>
      <v-btn
        v-else
        depressed
        :class="config.btnClass"
        :color="color"
        v-bind="attrs"
        v-on="on"
        @click="modal = !modal"
      >
        <slot name="btn-content">
          <v-icon class="mr-2">
            {{ icons.mdiPlus }}
          </v-icon>
          <span>{{ config.btnText }}</span>
        </slot>
      </v-btn>
    </template>
    <v-form
      v-if="modal"
      ref="form"
      v-model="valid"
      @submit.prevent="onSubmit"
    >
      <v-card   v-if="modal">
        <v-card-title>
          <slot name="form-title">
            <span class="font-weight-semibold text-base text--primary">{{
                config.title || 'Form'
              }}</span>
            <v-spacer/>
            <v-spacer></v-spacer>
            <v-btn
              icon
              small
              @click="modal = false"
            >
              <v-icon size="22">
                {{ icons.mdiClose }}
              </v-icon>
            </v-btn>
          </slot>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col :cols="config.left || 12">
              <v-row>
                <template v-for="field in fields">
                  <v-col
                    :key="field.value"
                    cols="12"
                    :sm="field.sm || 12"
                    :md="field.md || 12"
                    :lg="field.lg || 12"
                    :xl="field.xl || 12"
                  >
                    <template v-if="field.type === 'text'">
                      <translatable-text-field
                        v-if="field.translatable"
                        v-model="item[field.value]"
                        :label="field.text"
                        outlined
                        dense
                        clearable
                      ></translatable-text-field>
                      <v-text-field
                        v-else
                        v-model="item[field.value]"
                        :label="field.text"
                        outlined
                        dense
                        clearable
                      ></v-text-field>
                    </template>

                    <v-text-field
                      v-else-if="field.type === 'number'"
                      v-model="item[field.value]"
                      :label="field.text"
                      type="number"
                      outlined
                      dense
                      clearable
                    ></v-text-field>
                    <!--                <v-select
                                      v-else-if="field.type === 'select'"
                                      v-model="item[field.column_name || field.value]"
                                      :label="field.text"
                                      :items="field.items || []"
                                      :item-text="field.itemLabel || 'label'"
                                      :item-value="field.itemValue || 'id'"
                                      clearable
                                      outlined
                                      dense
                                    ></v-select>-->
                    <v-autocomplete
                      v-else-if="field.type === 'select' && !field.source"
                      v-model="item[field.column_name || field.value]"
                      :label="field.text"
                      :items="field.items || []"
                      :item-text="field.itemLabel || 'label'"
                      :item-value="field.itemValue || 'id'"
                      :multiple="field.multiple"
                      clearable
                      outlined
                      dense
                    ></v-autocomplete>
                    <i-autocomplete
                      v-else-if="field.type === 'select' && field.source"
                      v-model="item[field.column_name || field.value]"
                      :label="field.text"
                      :item-text="(field.translatable?`${field.itemLabel}.${$i18n.locale}`: field.itemLabel || 'label')"
                      :item-value="field.itemValue || 'id'"
                      :multiple="field.multiple"
                      :source="field.source"
                      :object="item[field.object || 'user']"
                      clearable
                      outlined
                      dense
                    ></i-autocomplete>
                    <date-picker
                      v-else-if="field.type === 'date' && !field.range"
                      v-model="item[field.value]"
                      :max-date="field.maxDate || null"
                      :min-date="field.minDate || null"
                      :label="field.text"
                    />
                    <date-range-picker
                      v-else-if="field.type === 'date' && field.range"
                      v-model="item[field.value]"
                      :label="field.text"
                      :max-date="field.maxDate || null"
                      :min-date="field.minDate || null"
                      first-day-of-week="1"
                      no-title
                      scrollable
                      :type="field.month?'month':'date'"
                      style="min-width: 67px"
                    />
                    <v-color-picker
                      v-else-if="field.type === 'color'"
                      v-model="item[field.value]"
                      :label="field.text"
                      mode="hex"
                      outlined
                      dense
                      hide-details="auto"
                    ></v-color-picker>
                    <template v-else-if="field.type === 'textarea'">
                      <translatable-textarea
                        v-if="field.translatable"
                        :editor="field.editor"
                        v-model="item[field.value]"
                        outlined
                        :rows="field.rows || 1"
                        auto-grow
                        dense
                        :label="field.text"
                        :placeholder="field.text"
                        hide-details="auto"
                      ></translatable-textarea>
                      <v-textarea
                        v-else
                        v-model="item[field.value]"
                        outlined
                        :rows="field.rows || 1"
                        auto-grow
                        dense
                        :label="field.text"
                        :placeholder="field.text"
                        hide-details="auto"
                      ></v-textarea>
                    </template>
                    <template v-else-if="field.type === 'checkbox' || field.type === 'check'">
                      <v-switch
                        v-model="item[field.value]"
                        :label="field.text"
                        :true-value="field.trueValue"
                        :false-value="field.falseValue"
                      ></v-switch>
                    </template>
                    <template v-else-if="field.type === 'image'">
                      <div
                        class="me-3 "
                        :style="{'width': field.width || '100px'}"
                      >
                        <span>{{ field.text || $t('Photo') }}</span>
                        <file-upload
                          v-model="item[field.value]"
                          add-class="mx-auto justify-center"
                          :label="$t('Upload new photo')"
                          folder="images/packages/avatars/"
                          :btn="true"
                          :extra="false"
                          :hide-label="false"
                          icon-name="mdi-pencil"
                          :icon="false"
                          :show-download="false"
                          inputname="file"
                          :crop="true"
                          :aspect-ratio="field.ratio || 16/9"
                          :max-crop-height="field.cropHeight || 700"
                          :max-crop-width="field.cropWidth || 1400"
                          accept="image/*"
                        >
                          <template #input>
                            <v-avatar
                              :color="item[field.value] ? '' : 'primary'"
                              :class="item[field.value] ? '' : 'v-avatar-light-bg primary--text'"
                              :width="field.width || '100px'"
                              :height="field.height || '100px'"
                              rounded
                              class="mb-4"
                            >
                              <v-img
                                v-if="item[field.value]"
                                :lazy-src="require(`@/assets/images/avatars/1.png`)"
                                :src="item[field.value] || require(`@/assets/images/avatars/1.png`)"
                              ></v-img>
                              <span
                                v-else
                                class="font-weight-semibold text-5xl"
                              >{{ avatarText(field.text) }}</span>
                              <v-icon
                                size="20"
                                color="primary"
                                style="position: absolute;bottom: 10px; left: 10px"
                              >
                                {{ icons.mdiCloudUploadOutline }}
                              </v-icon>
                            </v-avatar>
                          </template>
                        </file-upload>
                      </div>
                    </template>
                  </v-col>
                </template>
              </v-row>
            </v-col>
            <v-col :cols="config.right || 12">
              <slot name="form-additional">
              </slot>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <slot name="form-actions">
            <slot name="form-cancel">
              <v-btn
                :loading="loading"
                color="secondary"
                outlined
                type="reset"
                @click="modal= false"
              >
                {{ $t('Cancel') }}
              </v-btn>
            </slot>
            <v-spacer/>
            <v-btn
              :loading="loading"
              color="primary"
              class="me-3"
              type="submit"
            >
              {{ $t('Save') }}
            </v-btn>
          </slot>
        </v-card-actions>
      </v-card>
      <v-snackbar
        v-model="isSnackbarBottomVisible"
        :color="successful?$vuetify.theme.currentTheme.success:$vuetify.theme.currentTheme.error"

        dark
      >
        {{ snackBarMessage }}
      </v-snackbar>
    </v-form>
  </v-dialog>
</template>

<script>

// eslint-disable-next-line object-curly-newline
import {
  mdiCalendar,
  mdiCalendarRange,
  mdiFilter,
  mdiPlus,
  mdiClose,
  mdiPencilOutline,
} from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import { i18n } from '@/plugins/i18n'
import DatePicker from '@/components/DatePicker'
import DateRangePicker from '@/components/DateRangePicker'
import FileUpload from '@/components/file_upload'
import axios from '@axios'
import IAutocomplete from "@/components/IAutocomplete";
import TranslatableTextField from "@/components/TranslatableTextField";
import TranslatableTextarea from "@/components/TranslatableTextarea";

export default {
  components: {
    TranslatableTextarea,
    TranslatableTextField,
    IAutocomplete,
    FileUpload,
    DateRangePicker,
    DatePicker,
  },
  props: {
    object: {
      type: Object,
      default: () => {
      },
    },
    headers: {
      type: Array,
      default: () => [],
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    color: {
      type: String,
      default: 'primary',
    },
    showButton: {
      type: Boolean,
      default: true,
    },
    config: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const form = ref()
    const errorMessages = ref({})
    const isSnackbarBottomVisible = ref(false)
    const snackBarMessage = ref('')
    const successful = ref(false)
    const valid = ref(false)
    const loading = ref(false)
    const modal = ref(false)
    const fields = ref([])
    const today = ref((new Date(Date.now())).toISOString()
      .substr(0, 10))
    const item = computed({
      get: () => props.object || {},
      set: value => emit('update:object', value),
    })
    if (!item.value.id) item.value = {}
    const items = computed({
      get: () => props.headers.map(ele => {
        ele.menu = ele.menu || false

        return ele
      }),
      set: value => emit('update:object', value),
    }, { deep: true })

    const validate = () => {
      form.value.validate()
    }
    const dialog = computed({
      get: () => props.isFormActive,
      set: value => emit('is-form-active', value),
    })
    const onSubmit = () => {
      if (valid.value) {
        const payload = {
          id: item.value.id,
        }

        if (fields.value && fields.value.length > 0) {
          for (let i = 0; i < fields.value.length; i++) {
            if (fields.value[i].required && !item.value[fields.value[i].column_name || fields.value[i].value]) {
              isSnackbarBottomVisible.value = true
              successful.value = false
              snackBarMessage.value = fields.value[i].requiredMessage || `The field "${fields.value[i].text}" is required`

              return false
            }

            payload[fields.value[i].column_name || fields.value[i].value] = item.value[fields.value[i].column_name || fields.value[i].value] || null
          }
        }

        loading.value = true
        axios.post(`${props.config.url}`,
          payload)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                successful.value = false
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return true
            }

            item.value = response.data
            snackBarMessage.value = props.config.successMessage || `Form saved successfully`
            isSnackbarBottomVisible.value = true
            successful.value = true
            modal.value = false
            // emit('update:is-form-active', false)
            emit('saved', response.data)

            // item.value.comment = null
          })
          .catch(error => {
            isSnackbarBottomVisible.value = true
            successful.value = false
            snackBarMessage.value = 'Error saving form'
            loading.value = false
            console.log('error', error)
            if (error.data && error.data.errors) {
              // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }

    fields.value = JSON.parse(JSON.stringify(items.value))

    return {
      form,
      formatDate,
      items,
      fields,
      today,
      item,
      loading,
      valid,
      dialog,
      validate,
      avatarText,
      onSubmit,
      successful,
      isSnackbarBottomVisible,
      snackBarMessage,
      modal,
      icons: {
        mdiCalendar,
        mdiCalendarRange,
        mdiFilter,
        mdiPlus,
        mdiClose,
        mdiPencilOutline,
      },
    }
  },
}
</script>
